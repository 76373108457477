import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
  
})
export class HeaderComponent implements OnInit {
  isUserLogin: boolean = false;
  userId: string = "";
  userName: string = "";
  constructor(private _router: Router) {
    debugger;
    if (localStorage.getItem("invSiteUserId") != null && localStorage.getItem("invSiteUserId") != undefined) {
      this.userId = (localStorage.getItem("invSiteUserId"));
      this.userName = localStorage.getItem("invSiteUserName");
    }
    if (this.userId != "" && this.userId != null && this.userId != undefined) {
      this.isUserLogin = true;
    }}

  ngOnInit() {
   

  }
  logOut() {
    debugger;
    localStorage.removeItem("invSiteUserId");
    localStorage.removeItem("invSiteUserName");
    localStorage.removeItem("invSiteUserEmailId");
    window.location.href = '/signin';
    //this._router.navigate(['/login']);
  }

}
