import { Injectable } from "@angular/core";
export const Globals = Object.freeze({
  contentType: 'application/json',
  apiKey: 'hy58ki45-3hrt-hj48-h48i-584nju58io',
  InvoiceMakerUrl: 'http://localhost:11917/api/',
  noOfFreeInvoice : 10,
  
});
@Injectable()
export class GlobalProvider {
  public paginationRecordSize: number = 10;
  public dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
