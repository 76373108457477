import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalProvider, Globals } from '../../directives/global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-company-client-save',
  templateUrl: './company-client-save.component.html'
})
export class CompanyClientSaveComponent implements OnInit {
  id: number;
  saveForm: FormGroup;
  btntitle: string = "Save";
  tabtitle: string = "Add New";
  dropdownSettings = {};
  public locationSettings = {}
  public location: string = "";
  headers = new HttpHeaders;
  userId: string = "";
  isUpdate: boolean = false;

  constructor(private spinnerService: Ng4LoadingSpinnerService, public _http: HttpClient, private _router: Router,
    public toastr: ToastrManager, public global: GlobalProvider, private _avRoute: ActivatedRoute, private _fb: FormBuilder) {
  
    if (this._avRoute.snapshot.params["id"]) {
      this.id = this._avRoute.snapshot.params["id"];
    }
    if (localStorage.getItem("invSiteUserId") != null && localStorage.getItem("invSiteUserId") != undefined) {
      this.userId = (localStorage.getItem("invSiteUserId"));
    }
    if (this.userId != "" && this.userId != null && this.userId != undefined) {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey).set('User_Id', this.userId);
    }
    else {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    }
    this.saveForm = this._fb.group({

      clientName: ['', [Validators.required, Validators.maxLength(300)]],
      emailId: ['', [Validators.required, Validators.maxLength(200), Validators.email]],
      address: [''],
      phone: ['', [Validators.maxLength(50)]],
      businessNumber: ['', [Validators.maxLength(50)]],
      isActive: [true],
    })
    this.saveForm = new FormGroup(this.saveForm.controls, { updateOn: 'blur' });
  }

  get clientName() { return this.saveForm.get('clientName'); }
  get emailId() { return this.saveForm.get('emailId'); }
  get address() { return this.saveForm.get('address'); }
  get phone() { return this.saveForm.get('phone'); }
  get businessNumber() { return this.saveForm.get('businessNumber'); }
  get isActive() { return this.saveForm.get('isActive'); }
  get f() { return this.saveForm.controls }

 
  onLocationChange(selectedData: any) {
    if (selectedData.response) {
      this.location = selectedData.data.description;
    }
    else {
      this.location = "";
    }
  }
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true
    };
    this.locationSettings = {
      showSearchButton: false,
      showCurrentLocation: true,
      showRecentSearch: false,
      inputPlaceholderText: "Select Location",
      geoTypes: ['(cities)']
    };

    if (this.id > 0) {
      this.spinnerService.show();
      this.btntitle = "Update";
      this.tabtitle = "Update";
      this.isUpdate = true;
      const headers = this.headers;
      var body = {
        clientId: this.id
      };
      this._http.post<any>(Globals.InvoiceMakerUrl + '/ClientsAPI/GetDetailById', body, { headers }).subscribe(result => {
        debugger;
        this.saveForm.patchValue({ clientName: result.data.ClientDetail.clientName });
        this.saveForm.patchValue({ emailId: result.data.ClientDetail.emailId });
        this.saveForm.patchValue({ address: result.data.ClientDetail.address });
        this.saveForm.patchValue({ phone: result.data.ClientDetail.phone });
        this.saveForm.patchValue({ businessNumber: result.data.ClientDetail.businessNumber });
        this.saveForm.patchValue({ isActive: result.data.ClientDetail.isActive });
        if (result.data.ClientDetail.location != null) {
          this.location = result.data.ClientDetail.location;
          this.locationSettings = {
            showSearchButton: false,
            showCurrentLocation: true,
            showRecentSearch: false,
            inputPlaceholderText: result.data.ClientDetail.location,
            geoTypes: ['(cities)']
          };
        }
        this.spinnerService.hide();
      }, error => console.error(error));
    }
  }

  save() {
    if (!this.saveForm.valid) {
      return;
    }
    debugger;

    this.spinnerService.show();
    const headers = this.headers;
    var body =
    {
      clientId: this.id, emailId: this.f.emailId.value, clientName: this.f.clientName.value, address: this.f.address.value,
      location: this.location, phone: this.f.phone.value, businessNumber: this.f.businessNumber.value, isActive: this.f.isActive.value
    };
    this._http.post<any>(Globals.InvoiceMakerUrl + '/ClientsAPI/AddUpdate', body, { headers }).subscribe(result => {
      debugger;
      this.spinnerService.hide();
      if (result.status == "success") {
        this.toastr.successToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
        this._router.navigate(['/clients']);
      }
      else {
        this.toastr.errorToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
      }
    }, error => console.error(error));
  }

  cancel() {
    this._router.navigate(['/clients']);
  }
}
