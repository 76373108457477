import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalProvider, Globals } from '../directives/global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html'

})
export class CompanyComponent implements OnInit {

  saveForm: FormGroup;
  companyLogo: File;
  public isLogoChange: boolean = false;
  imgCompanyLogo: any = "#";
  public locationSettings = {}
  public location: string = "";
  currencyList = [];
  taxTypeList = [];
  companyId: string = "";
  colorCode: string = "#2d61a6";
  taxTypeSelected: string = "None";
  isTaxType: boolean = false;
  userId: string = "";
  headers = new HttpHeaders;
  macAddress: string = "";
  isTaxLabelType: boolean = false;

  constructor(private _fb: FormBuilder, private spinnerService: Ng4LoadingSpinnerService, public _http: HttpClient,
    private _router: Router, public toastr: ToastrManager) {
   
    if (localStorage.getItem("invSiteUserId") != null && localStorage.getItem("invSiteUserId") != undefined) {
      this.userId = (localStorage.getItem("invSiteUserId"));
    }
    if (this.userId != "" && this.userId != null && this.userId != undefined) {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey).set('User_Id', this.userId);
    }
    else {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    }
    this.getFieldList();
    this.saveForm = this._fb.group({
      companyName: ['', [Validators.required, Validators.maxLength(200)]],
      emailId: ['', [Validators.required, Validators.maxLength(200), Validators.email]],
      addressLine1: ['', [Validators.maxLength(200)]],
      addressLine2: ['', [Validators.maxLength(200)]],
      phone: ['', [Validators.maxLength(50)]],
      businessLable: ['', [Validators.maxLength(100)]],
      businessNumber: ['', [Validators.maxLength(100)]],
      taxType :['None'],
      taxRate: [0, [Validators.min(0)]],
      taxLable: ['', [Validators.maxLength(50)]],
      taxIsInclusive: [false],
      currencyId :[1],
      invTitle: ['', [Validators.maxLength(50)]],
      invNumber: ['', [Validators.maxLength(50)]],
      invDefaultNotes: ['', [Validators.maxLength(2000)]],
      estimateTitle: ['', [Validators.maxLength(50)]],
      estimateNumber: ['', [Validators.maxLength(50)]],
      estimateDefaultNotes: ['', [Validators.maxLength(2000)]],
    })
    this.saveForm = new FormGroup(this.saveForm.controls, { updateOn: 'blur' });
  }
  get companyName() { return this.saveForm.get('companyName'); }
  get emailId() { return this.saveForm.get('emailId'); }
  get addressLine1() { return this.saveForm.get('addressLine1'); }
  get addressLine2() { return this.saveForm.get('addressLine2'); }
  get phone() { return this.saveForm.get('phone'); }
  get businessLable() { return this.saveForm.get('businessLable'); }
  get businessNumber() { return this.saveForm.get('businessNumber'); }
  get taxType() { return this.saveForm.get('taxType'); }
  get taxRate() { return this.saveForm.get('taxRate'); }
  get taxLable() { return this.saveForm.get('taxLable'); }
  get taxIsInclusive() { return this.saveForm.get('taxIsInclusive'); }
  get currencyId() { return this.saveForm.get('currencyId'); }
  get invTitle() { return this.saveForm.get('invTitle'); }
  get invNumber() { return this.saveForm.get('invNumber'); }
  get invDefaultNotes() { return this.saveForm.get('invDefaultNotes'); }
  get estimateTitle() { return this.saveForm.get('estimateTitle'); }
  get estimateNumber() { return this.saveForm.get('estimateNumber'); }
  get estimateDefaultNotes() { return this.saveForm.get('estimateDefaultNotes'); }
 
  get f() { return this.saveForm.controls }
  onSelectTaxType(val) {

    this.taxTypeSelected = val;
    if (val == "None" || val == "Per Item") {
      this.saveForm.patchValue({ taxLabel: 'Tax' });
      this.saveForm.patchValue({ taxPer: 0.00 });
      this.saveForm.patchValue({ taxInclusive: false });
      this.isTaxLabelType = false;
      this.isTaxType = false;
      if (val == "Per Item") {
        this.isTaxLabelType = true;

      }
    }
    else {
      this.isTaxLabelType = true;

      this.isTaxType = true;
    }
  }
  getFieldList() {
    this.spinnerService.show();
    debugger;
    const headers = this.headers;
    this._http.post<any>(Globals.InvoiceMakerUrl + '/CompanyAPI/GetCompanySetting', {}, { headers }).subscribe(result => {
      debugger;
      
      this.taxTypeList = result.data.taxTypes;
      this.currencyList = result.data.currencyList;
      this.macAddress = result.data.macAddress;

      if (result.data.companyDetail != null) {
       
        this.saveForm.patchValue({ companyName: result.data.companyDetail.companyName });
        this.saveForm.patchValue({ emailId: result.data.companyDetail.emailId });
        this.saveForm.patchValue({ addressLine1: result.data.companyDetail.addressLine1 });
        this.saveForm.patchValue({ addressLine2: result.data.companyDetail.addressLine2 });
        this.saveForm.patchValue({ phone: result.data.companyDetail.phone });
        this.saveForm.patchValue({ businessNumber: result.data.companyDetail.businessNumber });
        this.imgCompanyLogo = result.data.companyDetail.logo;
        if (result.data.companyDetail.location != null) {
          this.location = result.data.companyDetail.location;
          this.locationSettings = {
            showSearchButton: false,
            showCurrentLocation: true,
            showRecentSearch: false,
            inputPlaceholderText: "Select Location",
            inputString: this.location,
            geoTypes: ['(cities)']
          };
        }
        this.saveForm.patchValue({ businessLable: result.data.companyDetail.businessLable });
        if (result.data.companyDetail.colorCode != null) {
          this.colorCode = result.data.companyDetail.colorCode;
        }
        if (result.data.companyDetail.currencyId != null && Number(result.data.companyDetail.currencyId) > 0) {
          this.saveForm.patchValue({ currencyId: result.data.companyDetail.currencyId });
        }
        if (result.data.companyDetail.taxType != null) {
          this.saveForm.patchValue({ taxType: result.data.companyDetail.taxType });
          this.taxTypeSelected = result.data.companyDetail.taxType;
          if (this.taxTypeSelected == "None" || this.taxTypeSelected  == "Per Item") {
            this.saveForm.patchValue({ taxLabel: 'Tax' });
            this.saveForm.patchValue({ taxPer: 0.00 });
            this.saveForm.patchValue({ taxInclusive: false });

            this.isTaxType = false;
            this.isTaxLabelType = false;
            if (this.taxTypeSelected == "Per Item") {
              this.isTaxLabelType = true;

            }
          }
          else {
            this.isTaxLabelType = true;

            this.saveForm.patchValue({ taxRate: result.data.companyDetail.taxRate });
            this.saveForm.patchValue({ taxLable: result.data.companyDetail.taxLable });
            this.saveForm.patchValue({ taxIsInclusive: result.data.companyDetail.taxIsInclusive });
            this.isTaxType = true;
          }
        }
       
        this.saveForm.patchValue({ invTitle: result.data.companyDetail.invTitle });
        this.saveForm.patchValue({ invNumber: result.data.companyDetail.invNumber });
        this.saveForm.patchValue({ invDefaultNotes: result.data.companyDetail.invDefaultNotes });
        this.saveForm.patchValue({ estimateTitle: result.data.companyDetail.estimateTitle });
        this.saveForm.patchValue({ estimateNumber: result.data.companyDetail.estimateNumber });
        this.saveForm.patchValue({ estimateDefaultNotes: result.data.companyDetail.estimateDefaultNotes });
      }

      this.spinnerService.hide();
    }, error => console.error(error));
  }
  readLogoFile(event) {
    //alert('upload Banner called');
    this.companyLogo = event.target.files[0];
    this.isLogoChange = true;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event) => {
      this.imgCompanyLogo = (<FileReader>event.target).result;

    }
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.locationSettings = {
      showSearchButton: false,
      showCurrentLocation: true,
      showRecentSearch: false,
      inputPlaceholderText: "Select Location",
      geoTypes: ['(cities)']
    };

  
    
  }
  onColorSelect(_code) {
    this.colorCode = _code;
  }
  onLocationChange(selectedData: any) {
    if (selectedData.response) {
      this.location = selectedData.data.description;
    }
    else {
      this.location = "";
    }
  }
  save() {
    debugger;
    if (!this.saveForm.valid) {
      return;
    }
    this.spinnerService.show();
    let taxType = "";
    if (this.taxTypeSelected != "None") {
      taxType = this.taxTypeSelected;
    }
    if (this.taxTypeSelected == "None" || this.taxTypeSelected == "Per Item") {
      this.saveForm.patchValue({ taxLabel: '' });
      this.saveForm.patchValue({ taxPer: 0.00 });

    }
    const headers = this.headers;
    var body =
    {
      companyId: 0, companyName: this.f.companyName.value, emailId: this.f.emailId.value, addressLine1: this.f.addressLine1.value,
      addressLine2: this.f.addressLine2.value, location: this.location, phone: this.f.phone.value, businessLable: this.f.businessLable.value, businessNumber: this.f.businessNumber.value,
      colorCode: this.colorCode, currencyId: this.f.currencyId.value, taxType: taxType, taxRate: this.f.taxRate.value, taxLable: this.f.taxLable.value, taxIsInclusive: this.f.taxIsInclusive.value,
      invTitle: this.f.invTitle.value, invNumber: this.f.invNumber.value, invDefaultNotes: this.f.invDefaultNotes.value, estimateTitle: this.f.estimateTitle.value,
      estimateNumber: this.f.estimateNumber.value, estimateDefaultNotes: this.f.estimateDefaultNotes.value, 
    };
    this._http.post<any>(Globals.InvoiceMakerUrl + '/CompanyAPI/AddUpdateCompanySetting', body, { headers }).subscribe(result => {
      debugger;
      this.spinnerService.hide();
      if (result.status == "success") {
        this.companyId = result.data.companyId;
        if (this.isLogoChange) {
          debugger
          const uploadData = new FormData();
          uploadData.append(this.companyId, this.companyLogo, this.companyLogo.name);
          this._http.post<any>(Globals.InvoiceMakerUrl + '/UploadPicAPI/UploadCompanyLogo', uploadData).subscribe(result => {
            debugger;
          }, error => console.error(error));
        }

        this.toastr.successToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
        this._router.navigate(['/setting']);
      }
      else {
        this.toastr.errorToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
      }
    }, error => console.error(error));
  }
}
