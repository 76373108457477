import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalProvider, Globals } from '../../directives/global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-items-save',
  templateUrl: './items-save.component.html'
})
export class ItemsSaveComponent implements OnInit {
  id: number;
  saveForm: FormGroup;
  btntitle: string = "Save";
  tabtitle: string = "Add New";
  headers = new HttpHeaders;
  userId: string = "";

  constructor(private spinnerService: Ng4LoadingSpinnerService, public _http: HttpClient, private _router: Router,
    public toastr: ToastrManager, public global: GlobalProvider, private _avRoute: ActivatedRoute, private _fb: FormBuilder) {
  
    if (this._avRoute.snapshot.params["id"]) {
      this.id = this._avRoute.snapshot.params["id"];
    }
    if (localStorage.getItem("invSiteUserId") != null && localStorage.getItem("invSiteUserId") != undefined) {
      this.userId = (localStorage.getItem("invSiteUserId"));
    }
    if (this.userId != "" && this.userId != null && this.userId != undefined) {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey).set('User_Id', this.userId);
    }
    else {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    }
    this.saveForm = this._fb.group({
      itemName: ['', [Validators.required,Validators.maxLength(500)]],
      itemDesc: ['', [Validators.maxLength(500)]],
      itemRate: [0, [ Validators.min(0)]],
      taxPer: [0, [Validators.min(0)]],
      istaxable: [false],
      isActive: [true],
    })
    this.saveForm = new FormGroup(this.saveForm.controls, { updateOn: 'blur' });
  }

  get itemName() { return this.saveForm.get('itemName'); }
  get itemDesc() { return this.saveForm.get('itemDesc'); }
  get itemRate() { return this.saveForm.get('itemRate'); }
  get taxPer() { return this.saveForm.get('taxPer'); }
  get istaxable() { return this.saveForm.get('istaxable'); }
  get isActive() { return this.saveForm.get('isActive'); }
  get f() { return this.saveForm.controls }

 
  ngOnInit() {

    if (this.id > 0) {
      this.spinnerService.show();
      this.btntitle = "Update";
      this.tabtitle = "Update";
      const headers = this.headers;
      var body = {
        itemId: this.id
      };
      this._http.post<any>(Globals.InvoiceMakerUrl + '/ItemAPI/GetDetailById', body, { headers }).subscribe(result => {
        debugger;
        this.saveForm.patchValue({ itemName: result.data.ItemDetail.itemName });
        this.saveForm.patchValue({ itemRate: result.data.ItemDetail.itemRate });
        this.saveForm.patchValue({ itemDesc: result.data.ItemDetail.itemDesc });
        this.saveForm.patchValue({ taxPer: result.data.ItemDetail.taxPer });
        this.saveForm.patchValue({ istaxable: result.data.ItemDetail.istaxable });
        this.saveForm.patchValue({ isActive: result.data.ItemDetail.isActive });
        this.spinnerService.hide();
      }, error => console.error(error));
    }
  }

  save() {
    if (!this.saveForm.valid) {
      return;
    }
    debugger;

    this.spinnerService.show();
    const headers = this.headers;
    var body =
    {
      itemId: this.id, itemName: this.f.itemName.value, itemRate: this.f.itemRate.value, itemDesc: this.f.itemDesc.value,
      taxPer: this.f.taxPer.value, istaxable: this.f.istaxable.value, isActive: this.f.isActive.value
    };
    this._http.post<any>(Globals.InvoiceMakerUrl + '/ItemAPI/AddUpdate', body, { headers }).subscribe(result => {
      debugger;
      this.spinnerService.hide();
      if (result.status == "success") {
        this.toastr.successToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
        this._router.navigate(['/items']);
      }
      else {
        this.toastr.errorToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
      }
    }, error => console.error(error));
  }

  cancel() {
    this._router.navigate(['/items']);
  }
}
