import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalProvider, Globals } from '../../directives/global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html'
})
export class ItemsComponent implements OnInit {
  public objList: any;
  isClearFilter: boolean = false;
  searchByName: string = "";
  config: any;
  headers = new HttpHeaders;
  userId: string = "";

  constructor(private spinnerService: Ng4LoadingSpinnerService, public _http: HttpClient, private _router: Router, public toastr: ToastrManager, public global: GlobalProvider) {
    if (localStorage.getItem("invSiteUserId") != null && localStorage.getItem("invSiteUserId") != undefined) {
      this.userId = (localStorage.getItem("invSiteUserId"));
    }
    if (this.userId != "" && this.userId != null && this.userId != undefined) {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey).set('User_Id', this.userId);
    }
    else {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    }
    this.config = {
      currentPage: 1,
      itemsPerPage: this.global.paginationRecordSize,
      totalItems: 20,
    };
   
    this.getList(1);
  }
 
  ngOnInit() {
   
  }
  pageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.getList(newPage);
  }
  getList(newPage) {
    this.spinnerService.show();
    const headers = this.headers ;
   
    var body = {
      itemName: this.searchByName,
      pageNo: newPage, recordSize: this.global.paginationRecordSize
    };
    this._http.post<any>(Globals.InvoiceMakerUrl + '/ItemAPI/GetItemListByPagination', body, { headers }).subscribe(result => {
      debugger;
      if (result.data.ItemsList.length > 0) {
        this.config.totalItems = result.data.ItemsList[0].inRecordCount;
      }
      else {
        this.config.totalItems = 0;
      }
      this.objList = result.data.ItemsList;
      this.spinnerService.hide();
    }, error => console.error(error));

  }
  onSearchFacility() {
    this.isClearFilter = true;
    this.getList(1);
  }
  onSeachClearFacility() {
    this.isClearFilter = false;
    this.searchByName = "";
    this.getList(1);
  }

  delete(objId) {
    const headers = this.headers;
    var body = {
      itemId: objId
    };
    var ans = confirm("Do you want to delete this Item?");
    if (ans) {
      this.spinnerService.show();
      this._http.post<any>(Globals.InvoiceMakerUrl + '/ItemAPI/DeleteById', body, { headers }).subscribe(result => {
        debugger;
        this.spinnerService.hide();
        if (result.status == "success") {
          this.toastr.successToastr(result.message, null, {
            maxShown: 1,
            toastTimeout: 2000
          });
          this.getList(1);
        }
        else {
          this.toastr.errorToastr(result.message, null, {
            maxShown: 1,
            toastTimeout: 2000
          });
        }
      }, error => console.error(error));
    }
  }
}
