import { BrowserModule } from '@angular/platform-browser';
import { Component, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { DatePipe } from '@angular/common';
import { AutocompleteModule } from 'ng2-input-autocomplete';
import { NgxPrintModule } from 'ngx-print';
import { GlobalProvider } from './directives/global';
import { NumericDirective } from './directives/numbers-only.directives';
import { AuthGuard } from './directives/auth.guard';

import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { HeaderComponent, FooterComponent } from './header-footer';
import { LayoutComponent } from './layout';
import { MakeInvoiceComponent, InvoiceListComponent, InvoiceDetailComponent, InvoiceViewComponent } from './invoice';
import { PricingComponent } from './pricing';
import { SignUpComponent, SignInComponent, ChangePasswordComponent } from './user';
import { EstimateDetailComponent, EstimateListComponent, EstimateViewComponent, MakeEstimateComponent} from './estimate';

import {
  CompanyComponent, CompanyClientComponent, CompanyClientSaveComponent, ItemsComponent, ItemsSaveComponent
} from './company';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    MakeInvoiceComponent,
    PricingComponent,
    SignUpComponent,
    SignInComponent,
    CompanyComponent,
    CompanyClientComponent,
    CompanyClientSaveComponent,
    ItemsComponent,
    ItemsSaveComponent,
    InvoiceListComponent,
    InvoiceDetailComponent,
    NumericDirective,
    ChangePasswordComponent,
    InvoiceViewComponent,
    EstimateDetailComponent,
    EstimateListComponent,
    EstimateViewComponent,
    MakeEstimateComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    Ng4LoadingSpinnerModule.forRoot(),
    ToastrModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    DpDatePickerModule,
    NgxPaginationModule,
    GooglePlaceModule,
    Ng4GeoautocompleteModule.forRoot(),
    AutocompleteModule.forRoot(),
    NgxPrintModule,
  ],
  providers: [GlobalProvider, DatePipe, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
