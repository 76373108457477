import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalProvider, Globals } from '../directives/global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { IDayCalendarConfig, DatePickerComponent } from "ng2-date-picker";

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html'
})
export class InvoiceListComponent implements OnInit {
  public objList: any;
  isClearFilter: boolean = false;
  searchByInvoiceNo: string = "";
  searchByFromDate: string = "";
  searchByToDate: string = "";
  config: any;
  headers = new HttpHeaders;
  userId: string = "";
  companyList = [];
  selectedCompany = [];
  dropdownSettings = {};
  clientList = [];
  selectedClient = [];
  public dayPickerConfig = <IDayCalendarConfig>{
    //locale: "de",
    format: "DD/MM/YYYY",
  };
  
  constructor(private spinnerService: Ng4LoadingSpinnerService, public _http: HttpClient, private _router: Router, public toastr: ToastrManager, public global: GlobalProvider) {
    if (localStorage.getItem("invSiteUserId") != null && localStorage.getItem("invSiteUserId") != undefined) {
      this.userId = (localStorage.getItem("invSiteUserId"));
    }
    if (this.userId != "" && this.userId != null && this.userId != undefined) {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey).set('User_Id', this.userId);
    }
    else {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    }
    this.config = {
      currentPage: 1,
      itemsPerPage: this.global.paginationRecordSize,
      totalItems: 20,
    };
    this.getdrpList();
    this.getList(1);
  }
  getdrpList() {
    this.spinnerService.show();
    const headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    this._http.post<any>(Globals.InvoiceMakerUrl + '/MakeInvoiceAPI/GetdrpListForInvoice', {}, { headers }).subscribe(result => {
      this.companyList = result.data.companyList;
      this.clientList = result.data.clientList;
      this.spinnerService.hide();
    }, error => console.error(error));
  }
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true
    };
  }
  pageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.getList(newPage);
  }
  getList(newPage) {
    this.spinnerService.show();
    const headers = this.headers;
    let selectedClientId = "";
    if (this.selectedClient.length > 0) {
      selectedClientId = (this.selectedClient[0].id).toString();
  
    }
    let selectedCompanyId = "";
    if (this.selectedCompany.length > 0) {
      selectedCompanyId = (this.selectedCompany[0].id).toString();
    
    }
    debugger;
    var body = {
      invoiceNo: this.searchByInvoiceNo, fromDate: this.searchByFromDate, toDate: this.searchByToDate,
      companyId: selectedCompanyId, clientId: selectedClientId,
      pageNo: newPage, recordSize: this.global.paginationRecordSize
    };
    this._http.post<any>(Globals.InvoiceMakerUrl + '/InvoiceAPI/GetInvoiceList', body, { headers }).subscribe(result => {
      debugger;
      if (result.data.InvoiceList.length > 0) {
        this.config.totalItems = result.data.InvoiceList[0].inRecordCount;
      }
      else {
        this.config.totalItems = 0;
      }
      this.objList = result.data.InvoiceList;
      this.spinnerService.hide();
    }, error => console.error(error));

  }
  onSearchFacility() {
    this.isClearFilter = true;
    this.getList(1);
  }
  onSeachClearFacility() {
    this.isClearFilter = false;
    this.searchByInvoiceNo = "";
    this.searchByFromDate = "";
    this.searchByToDate = "";
    this.selectedCompany = [];
    this.selectedClient = [];

    this.getList(1);
  }
 
  
}
