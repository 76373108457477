import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalProvider, Globals } from '../../directives/global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
  
})
export class SignUpComponent implements OnInit {
  saveForm: FormGroup;
  constructor(private _fb: FormBuilder,  private spinnerService: Ng4LoadingSpinnerService, public _http: HttpClient,
    private _router: Router, public toastr: ToastrManager) {

    this.saveForm = this._fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      emailId: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
      password: ['', [Validators.required, Validators.maxLength(20)]],
    })
    this.saveForm = new FormGroup(this.saveForm.controls, { updateOn: 'blur' });
  }
  get name() { return this.saveForm.get('name'); }
  get emailId() { return this.saveForm.get('emailId'); }
  get password() { return this.saveForm.get('password'); }
  get f() { return this.saveForm.controls }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  onSignUp() {

    if (!this.saveForm.valid) {
      return;
    }

    this.spinnerService.show();
    const headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    var body =
    {
      userId: "0", name: this.f.name.value, emailId: this.f.emailId.value, password: this.f.password.value
    }
    this._http.post<any>(Globals.InvoiceMakerUrl + '/UserAPI/UserSignUp', body, { headers }).subscribe(result => {
      debugger;
      this.spinnerService.hide();
      if (result.status == "success") {
        this.toastr.successToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
        this._router.navigate(['/signin']);
      }
      else {
        this.toastr.errorToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
      }
    }, error => console.error(error));
  }
}
