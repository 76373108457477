import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalProvider, Globals } from '../../directives/global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-changepassword',
  templateUrl: './change-password.component.html',

})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('f') mytemplateForm: NgForm;
  saveForm: FormGroup;
  userId: string = "";
  headers = new HttpHeaders;
  constructor(private spinnerService: Ng4LoadingSpinnerService,public _toastr: ToastrManager, private _fb: FormBuilder, private _router: Router, private _http: HttpClient, public global: GlobalProvider) {
    if (localStorage.getItem("invSiteUserId") != null && localStorage.getItem("invSiteUserId") != undefined) {
      this.userId = (localStorage.getItem("invSiteUserId"));
    }

    if (this.userId != "" && this.userId != null && this.userId != undefined) {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey).set('User_Id', this.userId);
    }
    else {
      
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    }
  }
  ngOnInit() {
    this.saveForm = this._fb.group({
      oldPassword: ['', [Validators.required, Validators.maxLength(20)]],
      newPassword: ['', [Validators.required, Validators.maxLength(20)]],
      encryptUserId : 0
    });
    this.saveForm = new FormGroup(this.saveForm.controls, { updateOn: 'blur' });
  }
  get oldPassword() { return this.saveForm.get('oldPassword'); }
  get newPassword() { return this.saveForm.get('newPassword'); }
  get f() { return this.saveForm.controls; }

  onSubmitChangePassword() {
   
    const headers = this.headers
      if (this.saveForm.invalid) {
        return;
    }
    this.spinnerService.show();
      var body = {
        oldPassword: this.f.oldPassword.value, newPassword: this.f.newPassword.value
    };
    this._http.post<any>(Globals.InvoiceMakerUrl + '/UserAPI/ChangeUserPassword', body, { headers }).subscribe(result => {
        debugger;
        this.spinnerService.hide();
        if (result.status == "success") {
          this._toastr.successToastr(result.message, null, { maxShown: 1, toastTimeout: 2000 });
          this.mytemplateForm.resetForm();
        }
        else {
          this._toastr.errorToastr(result.message, null, { maxShown: 1, toastTimeout: 2000 });
          this.mytemplateForm.resetForm();
        }
      }, error => console.error(error));
    
  }


 
}


