import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalProvider, Globals } from '../directives/global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDayCalendarConfig, DatePickerComponent } from "ng2-date-picker";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-make-estimate',
  templateUrl: './make-estimate.component.html'

})
export class MakeEstimateComponent implements OnInit {
  saveForm: FormGroup;
  public locationSettings = {}
  public clientlocationSettings = {};
  public location: string = "";
  public clientlocation: string = "";
  public dayPickerConfig: any;
  public dayEndPickerConfig: any;
  currenctdate: string = "";
  discountTypeList: [];
  isPercentDisType: boolean = false;
  isFlatAmtDisType: boolean = false;
  discountTypeSelected: string = "";
  isDiscountAttribute: boolean = false;
  DiscountTotal: string = "0.00";
  discountPercent: string = "0";
  discountCalValue: string = "0";

  taxTypeList: [];
  isTaxType: boolean = false;
  taxTypeSelected: string = "None";
  isTaxAttribute: boolean = false;
  isTaxLabelType: boolean = false;
  currencyList: [];
  currencyCode: string = 'INR';
  companyLogo: File;
  public isLogoChange: boolean = false;
  imgCompanyLogo: any = "#";
  //Cal Obj
  subTotal: string = "0.00";
  taxRateTotal: string = "0.00";
  taxPercent: string = "0";
  finalAmount: string = "0.00";

  invoiceItemList: any[];
  configItem: any = { 'placeholder': '','class': 'test', 'sourceField': ['itemName'] };
  companyDetail: any;
  macAddress: string = "";
  invoiceClientList: any[];
  configClient: any = { 'placeholder': '','class': 'test', 'sourceField': ['clientName'] };
  colorCode: string = "#2d61a6";
  companyId: string = "";
  userId: string = "";
  headers = new HttpHeaders;
  isNegativeFinalAmount: boolean = false;

  public objDetail: any;
  public isPreview: boolean = false;
  

  constructor(private _fb: FormBuilder, public datePipe: DatePipe, private spinnerService: Ng4LoadingSpinnerService, public _http: HttpClient,
    private _router: Router, public toastr: ToastrManager) {

    if (localStorage.getItem("invSiteUserId") != null && localStorage.getItem("invSiteUserId") != undefined) {
      this.userId = (localStorage.getItem("invSiteUserId"));
    }
    if (this.userId != "" && this.userId != null && this.userId != undefined) {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey).set('User_Id', this.userId);
    }
  else
    {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    }

    this.getFieldList();

    this.saveForm = this._fb.group({
      estimateTitle: ['Estimate', [Validators.maxLength(50)]],
      estimateNumber: ['', [Validators.required, Validators.maxLength(50)]],
      companyName: ['', [Validators.required, Validators.maxLength(200)]],
      emailId: ['', [Validators.required, Validators.maxLength(200), Validators.email]],
      addressLine1: ['', [Validators.maxLength(500)]],
      addressLine2: ['', [Validators.maxLength(500)]],
      phone: ['', [Validators.maxLength(50)]],
      businessNumber: ['', [Validators.maxLength(100)]],
      clientName: ['', [Validators.required]],
      clientemailId: ['', [Validators.required, Validators.maxLength(200), Validators.email]],
      clientaddress: ['', [Validators.maxLength(500)]],
      clientphone: ['', [Validators.maxLength(50)]],
      estimateDate: [''],
      dueDate: [''],
      discountType: ['None'],
      discountPerValue: [0.00, Validators.min(0)],
      discountFlatValue: [0.00, Validators.min(0)],
      taxType: ['None'],
      taxLabel: ['Tax', [Validators.maxLength(50)]],
      taxPer: [0.00, Validators.min(0)],
      taxInclusive: [false],
      currency: [1],
      ourRemark: ['', [Validators.maxLength(2000)]],
      invoiceItem: this._fb.array([
      ]),
      previewSendEmailId :[''],
    })
    this.saveForm = new FormGroup(this.saveForm.controls, { updateOn: 'blur' });
  }
  get estimateTitle() { return this.saveForm.get('estimateTitle'); }
  get estimateNumber() { return this.saveForm.get('estimateNumber'); }
  get companyName() { return this.saveForm.get('companyName'); }
  get emailId() { return this.saveForm.get('emailId'); }
  get addressLine1() { return this.saveForm.get('addressLine1'); }
  get addressLine2() { return this.saveForm.get('addressLine2'); }
  get phone() { return this.saveForm.get('phone'); }
  get businessNumber() { return this.saveForm.get('businessNumber'); }
  get clientName() { return this.saveForm.get('clientName'); }
  get clientemailId() { return this.saveForm.get('clientemailId'); }
  get clientaddress() { return this.saveForm.get('clientaddress'); }
  get clientphone() { return this.saveForm.get('clientphone'); }
  get estimateDate() { return this.saveForm.get('estimateDate'); }
  get dueDate() { return this.saveForm.get('dueDate'); }
  get discountType() { return this.saveForm.get('discountType'); }
  get discountPerValue() { return this.saveForm.get('discountPerValue'); }
  get discountFlatValue() { return this.saveForm.get('discountFlatValue'); }
  get taxType() { return this.saveForm.get('taxType'); }
  get taxLabel() { return this.saveForm.get('taxLabel'); }
  get taxPer() { return this.saveForm.get('taxPer'); }
  get taxInclusive() { return this.saveForm.get('taxInclusive'); }
  get currency() { return this.saveForm.get('currency'); }
  get ourRemark() { return this.saveForm.get('ourRemark'); }
  get previewSendEmailId() { return this.saveForm.get('previewSendEmailId'); }

  get f() { return this.saveForm.controls }

  getControls() {
    return (<FormArray>this.saveForm.get('invoiceItem')).controls;
  }
  private getInvoiceItem() {
    const numberPatern = '[0-9]*';
    return this._fb.group({
      itemId: [''],
      itemName: ['', [Validators.maxLength(500)]],
      itemDesc: ['', [Validators.maxLength(500)]],
      rate: [0, [ Validators.min(0)]],
      qty: ['1', [  Validators.min(1)]],
      taxper : [0]
    });
  }
 
  addInvoiceItem() {
    const control = <FormArray>this.saveForm.controls['invoiceItem'];
    control.push(this.getInvoiceItem());
  }
  removeInvoiceItem(i: number) {
    const control = <FormArray>this.saveForm.controls['invoiceItem'];
    control.removeAt(i);
    this.onInvoiceCalculation();
  }
  increaseValue(i) {
    debugger;
    const taxArray = <FormArray>this.saveForm.get('invoiceItem');
    let value = taxArray.controls[i].get('qty').value;
    taxArray.controls[i].get('qty').setValue(parseInt(value) + 1);
    this.onCheckQuantity(i);
  }
  decreaseValue(i) {
    const taxArray = <FormArray>this.saveForm.get('invoiceItem');
    let value = taxArray.controls[i].get('qty').value;
    taxArray.controls[i].get('qty').setValue(parseInt(value) - 1);
    this.onCheckQuantity(i);
  }
  onCheckQuantity(i) {
    const taxArray = <FormArray>this.saveForm.get('invoiceItem');
    let value = taxArray.controls[i].get('qty').value;
    if (value <= 1) {
      taxArray.controls[i].get('qty').setValue(1);
    }
    this.onInvoiceCalculation();
  }
  onInvoiceItemSelect(event,index) {
    debugger
    const taxArray = <FormArray>this.saveForm.get('invoiceItem');
    taxArray.controls[index].get('itemName').setValue(event.itemName);
    taxArray.controls[index].get('itemId').setValue(event.itemId);
    taxArray.controls[index].get('itemDesc').setValue(event.itemDesc);
    taxArray.controls[index].get('rate').setValue(event.itemRate);
    taxArray.controls[index].get('taxper').setValue(event.taxPer);
    this.onInvoiceCalculation();
  }
  onInputItemChangedEvent(event, index) {
    debugger;
    const taxArray = <FormArray>this.saveForm.get('invoiceItem');
    let value = taxArray.controls[index].get('itemId').value;
    taxArray.controls[index].get('itemName').setValue(event); 
    if (parseInt(value) > 0) { 
      taxArray.controls[index].get('itemId').setValue('0');
      taxArray.controls[index].get('itemDesc').setValue('');
      taxArray.controls[index].get('rate').setValue(0);
      taxArray.controls[index].get('taxper').setValue(0);
      this.onInvoiceCalculation();
    }
  }
  onInvoiceClientSelect(event) {
    this.saveForm.patchValue({ clientName: event.clientName });
    this.saveForm.patchValue({ clientemailId: event.emailId });
    this.saveForm.patchValue({ clientaddress: event.address });
    this.saveForm.patchValue({ clientphone: event.phone });
    this.clientlocation = event.location;
    if (this.clientlocation != null && this.clientlocation != "" && this.clientlocation != undefined) {
      this.clientlocationSettings = {
        showSearchButton: false,
        showCurrentLocation: true,
        showRecentSearch: false,
        inputPlaceholderText: "Select Location",
        inputString: this.clientlocation,
        geoTypes: ['(cities)']
      };
    } 
  }
  onInputClientChangedEvent(event) {
    this.saveForm.patchValue({ clientName: event});
  }
  onColorSelect(_code) {
    this.colorCode = _code;
  }
  onLocationChange(selectedData: any) {
    if (selectedData.response) {
      this.location = selectedData.data.description;
    }
    else {
      this.location = "";
    }
  }
  onClientLocationChange(selectedData: any) {
    if (selectedData.response) {
      this.clientlocation = selectedData.data.description;
    }
    else {
      this.clientlocation = "";
    }
  }
  readLogoFile(event) {
    //alert('upload Banner called');
    this.companyLogo = event.target.files[0];
    this.isLogoChange = true;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event) => {
      this.imgCompanyLogo = (<FileReader>event.target).result; 
    }
  }
  getFieldList() {
    this.spinnerService.show();
    const headers = this.headers;
    this._http.post<any>(Globals.InvoiceMakerUrl + '/MakeEstimateAPI/MakeEstimateFieldList', {}, { headers  }).subscribe(result => {
      debugger;
      this.discountTypeList = result.data.discountsTypes;
      this.taxTypeList = result.data.taxTypes;
      this.currencyList = result.data.currencyList;
      this.macAddress = result.data.macAddress; 
      if (result.data.companyDetail != null) {
        this.companyDetail = result.data.companyDetail;
        this.saveForm.patchValue({ companyName: result.data.companyDetail.companyName });
        this.saveForm.patchValue({ emailId: result.data.companyDetail.emailId });
        this.saveForm.patchValue({ addressLine1: result.data.companyDetail.addressLine1 });
        this.saveForm.patchValue({ addressLine2: result.data.companyDetail.addressLine2 });
        this.saveForm.patchValue({ phone: result.data.companyDetail.phone });
        this.saveForm.patchValue({ businessNumber: result.data.companyDetail.businessNumber });
        this.imgCompanyLogo = result.data.companyDetail.logo;
        if (result.data.companyDetail.location != null) {
          this.location = result.data.companyDetail.location;
          this.locationSettings = {
            showSearchButton: false,
            showCurrentLocation: true,
            showRecentSearch: false,
            inputPlaceholderText: "Select Location",
            inputString: this.location,
            geoTypes: ['(cities)']
          };
        }
        if (result.data.companyDetail.currencyId != null && Number(result.data.companyDetail.currencyId) > 0) {
          this.saveForm.patchValue({ currency: result.data.companyDetail.currencyId });
          this.currencyCode = result.data.companyDetail.currencyCode;
        }
        if (result.data.companyDetail.colorCode != null) {
          this.colorCode = result.data.companyDetail.colorCode;
        }
        if (result.data.companyDetail.taxType != null) {
          this.saveForm.patchValue({ taxType: result.data.companyDetail.taxType });
          this.taxTypeSelected = result.data.companyDetail.taxType;
          if (this.taxTypeSelected == "None" || this.taxTypeSelected  == "Per Item") {
            this.saveForm.patchValue({ taxLabel: 'Tax' });
            this.saveForm.patchValue({ taxPer: 0.00 });
            this.saveForm.patchValue({ taxInclusive: false });
            this.taxPercent = "0";
            this.isTaxType = false;

          }
          else {
            this.saveForm.patchValue({ taxLabel: result.data.companyDetail.taxLable });
            this.saveForm.patchValue({ taxPer: result.data.companyDetail.taxRate });
            this.saveForm.patchValue({ taxInclusive: result.data.companyDetail.taxIsInclusive  });
            this.taxPercent = result.data.companyDetail.taxRate ;
            this.isTaxType = true;
          }
        }
        if (this.taxTypeSelected == "None") {
          this.isTaxAttribute = false;
        }
        else {
          if (Number(this.taxPercent) > 0) {
            this.isTaxAttribute = true;
          }
          this.isTaxLabelType = true;

        }
        if (result.data.companyDetail.estimateTitle != null) {
          this.saveForm.patchValue({ estimateTitle: result.data.companyDetail.estimateTitle });
        }
        if (result.data.companyDetail.estimateNumber != null) {
          this.saveForm.patchValue({ estimateNumber: result.data.companyDetail.estimateNumber });
        } 
      } 
      this.invoiceItemList = result.data.itemList;
      this.invoiceClientList = result.data.clientList; 
      this.spinnerService.hide();
    }, error => console.error(error));
  } 
  onSelectDiscountType(val) {
    if (val == "None") {
      this.DiscountTotal = "0.00"
      this.isDiscountAttribute = false;
      this.saveForm.patchValue({ discountFlatValue: 0.00 });
      this.saveForm.patchValue({ discountPerValue: 0.00 });
      this.isPercentDisType = false;
      this.isFlatAmtDisType = false;
      this.onDiscountPerCal(0.00);
    }
    else { 
      this.isPercentDisType = true;
      this.isFlatAmtDisType = false;
      this.isDiscountAttribute = true;
      this.discountTypeSelected = val;
      this.saveForm.patchValue({ discountFlatValue: 0.00 });
      this.saveForm.patchValue({ discountPerValue: 0.00 });
      this.onDiscountPerCal(0.00);
      let element: HTMLInputElement = document.getElementById("discount_switch") as HTMLInputElement;
      if (element) {
        element.checked = true;
      }
    }
  }
  onDiscountCheck(input: HTMLInputElement) {
    if (input.checked === true) {
      this.isPercentDisType = true;
      this.isFlatAmtDisType = false;
      this.saveForm.patchValue({ discountFlatValue: 0.00 });
      this.onDiscountFlatCal(0.00);
    }
    else {
      this.isPercentDisType = false;
      this.isFlatAmtDisType = true;
      this.saveForm.patchValue({ discountPerValue: 0.00 });
      this.onDiscountPerCal(0.00);
    }
  }
  onDiscountPerCal(val) {
    this.discountPercent = val;
    this.discountCalValue = val; 
    this.onInvoiceCalculation();
  }
  onDiscountFlatCal(val) {
    this.discountCalValue = val;
    this.onInvoiceCalculation(); 
  } 
  onSelectTaxType(val) { 
    this.taxTypeSelected = val;
    if (val == "None" || val == "Per Item"  ) {
      this.saveForm.patchValue({ taxLabel: 'Tax' });
      this.saveForm.patchValue({ taxPer: 0.00 });
      this.saveForm.patchValue({ taxInclusive: false });
      this.taxPercent = "0";
      this.isTaxType = false;
      this.isTaxLabelType = false;
      if (val == "None") {
        this.isTaxAttribute = false;
      }
      else {
        if (Number(this.taxPercent) > 0) {
          this.isTaxAttribute = true;
        }
        this.isTaxLabelType = true;
      }
    }
    else {
      this.isTaxAttribute = true;
      this.isTaxLabelType = true;
      this.isTaxType = true;
    }
    this.onInvoiceCalculation();
  }
  onInvoiceCalculation() {
    this.taxRateTotal = "0.00";
    this.subTotal = "0.00";
    this.DiscountTotal = "0.00";
    let arrInvoiceItem = <FormArray>this.saveForm.get('invoiceItem');
     if (arrInvoiceItem.length > 0) {
       for (let i = 0; i < arrInvoiceItem.length; i++) {
         debugger;
         let elemrate: HTMLInputElement = document.getElementById("inv_rate_" + i) as HTMLInputElement;
         let rate = elemrate.value;
         if (Number(rate) <= 0) {
           arrInvoiceItem.controls[i].get('rate').setValue(0);
           elemrate.value = "0";
           rate = "0";
         }
         let elemqty: HTMLInputElement = document.getElementById("inv_qty_" + i) as HTMLInputElement;
         let qty = elemqty.value;
         if (Number(qty) <= 1) {
           arrInvoiceItem.controls[i].get('qty').setValue(1);
           elemqty.value = "1";
           qty = "1";
         }
        let total_amt = (parseFloat(rate) * parseFloat(qty)).toFixed(2);
        let total_amt_span: HTMLElement = document.getElementById("span_amt_" + i) as HTMLElement;
         total_amt_span.innerHTML = total_amt;
         this.subTotal = (parseFloat(this.subTotal) + parseFloat(total_amt)).toFixed(2);
         // discount per item cal 
         if (this.discountTypeSelected == "Per Item") {
           if (this.isPercentDisType == true) {
             let dis_per_cal = ((parseFloat(total_amt) * parseFloat(this.discountCalValue)) / 100).toFixed(2);
             this.DiscountTotal = (parseFloat(this.DiscountTotal) + parseFloat(dis_per_cal)).toFixed(2);
           }
           else if (this.isFlatAmtDisType == true) {
             this.DiscountTotal = (parseFloat(this.DiscountTotal) + parseFloat(this.discountCalValue)).toFixed(2);
           }
         }
         if (this.taxTypeSelected == "Per Item") {
           debugger;
           let tax_per = arrInvoiceItem.controls[i].get('taxper').value;
           let tax_per_cal = ((parseFloat(total_amt) * parseFloat(tax_per)) / 100).toFixed(2);
           this.taxRateTotal = (parseFloat(this.taxRateTotal) + parseFloat(tax_per_cal)).toFixed(2);
         }
         // end discount cal 
       }
    }
    // tax cal
    if (this.isTaxType == true) {
      this.taxRateTotal = ((parseFloat(this.subTotal) * parseFloat(this.taxPercent)) / 100).toFixed(2);
    }
    // discount on total cal
    if (this.discountTypeSelected == "On Total") {
      if (this.isPercentDisType == true) {
        
        this.DiscountTotal = ((parseFloat(this.subTotal) * parseFloat(this.discountCalValue)) / 100).toFixed(2);
      }
      else if (this.isFlatAmtDisType == true) {
        this.DiscountTotal = parseFloat(this.discountCalValue).toFixed(2);
      }
    }
    this.onFinalAmountCal();
  }
  onFinalAmountCal() {
    if (this.taxTypeSelected == "Deducted") {
      this.finalAmount = (parseFloat(this.subTotal) - parseFloat(this.DiscountTotal) - parseFloat(this.taxRateTotal)).toFixed(2);
    }
    else {
      this.finalAmount = (parseFloat(this.subTotal) - parseFloat(this.DiscountTotal) + parseFloat(this.taxRateTotal)).toFixed(2);
    }
    if (Number(this.finalAmount) >= 0) {
      this.isNegativeFinalAmount = false;
    }
    else {
      this.isNegativeFinalAmount = true;
    }
  }
  onTaxRateCal(val) {
    this.taxPercent = val;
    if (Number(this.taxPercent) > 0) {
      this.isTaxAttribute = true;
    }
    else {
      this.isTaxAttribute = false;

    }
    this.onInvoiceCalculation();
  }
  onSelectCurrency(event) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;
    this.currencyCode = selectElementText.match(/\(([^)]+)\)/)[1];
  }
  ngOnInit() {
    window.scrollTo(0, 0);
   
    this.addInvoiceItem();
    this.locationSettings = {
      showSearchButton: false,
      showCurrentLocation: true,
      showRecentSearch: false,
      inputPlaceholderText: "Select Location",
      geoTypes: ['(cities)']
    };
    this.clientlocationSettings = {
      showSearchButton: false,
      showCurrentLocation: true,
      showRecentSearch: false,
      inputPlaceholderText: "Select Location",
      geoTypes: ['(cities)']
    };

    var date = new Date();
    this.currenctdate = this.datePipe.transform(date, 'dd/MM/yyyy');
    this.dayPickerConfig = <IDayCalendarConfig>{
      format: "DD/MM/YYYY",
      min: this.currenctdate,
    };
    this.dayEndPickerConfig = <IDayCalendarConfig>{
      format: "DD/MM/YYYY",
      min: this.currenctdate,
    };
    this.saveForm.get("estimateDate").valueChanges.subscribe(value => {
      this.saveForm.patchValue({ endDate: '' });
      this.dayEndPickerConfig = {
        format: "DD/MM/YYYY",
        min: value,
      }
    });

    this.saveForm.get("estimateNumber").valueChanges.subscribe(value => {
      let element: HTMLElement = document.getElementById('inv_num_label') as HTMLElement;
      if (value) {
        element.innerText = value;
      }
      else {
        element.innerText = "Estimate Number"; 
      }
    });

    this.saveForm.get("emailId").valueChanges.subscribe(value => {
      this.saveForm.patchValue({ previewSendEmailId : value });
    });
  }
  

  onInvoiceSubmit() {
    debugger;
    if (!this.saveForm.valid) {
      return;
    }
    let taxType = "";
    if (this.taxTypeSelected != "None") {
      taxType = this.taxTypeSelected;
    }
    if (this.taxTypeSelected == "None" || this.taxTypeSelected == "Per Item") {
      this.saveForm.patchValue({ taxLabel: '' });
      this.saveForm.patchValue({ taxPer: 0.00 }); 
    }
    let discountType = "";
    let discountTypeCal = "Percent";
    if (this.discountTypeSelected != "None") {
      discountType = this.discountTypeSelected;
    }
    if (this.isFlatAmtDisType == true) {
      discountTypeCal = "Flat Amount";
    }
    else {
      discountTypeCal = "Percent"; 
    }
    const headers = this.headers;
    this.spinnerService.show();
    var body =
    {
      estimateId: "0", macAddress: this.macAddress, estimateTitle: this.f.estimateTitle.value, estimateNo: this.f.estimateNumber.value,
      estimateDate: this.f.estimateDate.value, dueDate: this.f.dueDate.value, ourRemark: this.f.ourRemark.value,
      companyName: this.f.companyName.value, companyEmailId: this.f.emailId.value, companyLocation: this.location, companyAddressLine1: this.f.addressLine1.value,
      companyAddressLine2: this.f.addressLine2.value, companyPhoneNo: this.f.phone.value, companyBusinessNo: this.f.businessNumber.value,
      clientName: this.f.clientName.value, clientEmailId: this.f.clientemailId.value, clientLocation: this.clientlocation,
      clientAddress: this.f.clientaddress.value, clientPhoneNo: this.f.clientphone.value, taxType: taxType,
      taxLabel: this.f.taxLabel.value, taxInclusive: this.f.taxInclusive.value, taxPer: this.f.taxPer.value, discountType: discountType,
      discountTypeCal: discountTypeCal, discountPerValue: this.f.discountPerValue.value, discountFlatValue: this.f.discountFlatValue.value,
      currencyId: this.f.currency.value, colorCode: this.colorCode, estimateItemLst: this.f.invoiceItem.value, previewEmailId: this.f.previewSendEmailId.value
    }
    this._http.post<any>(Globals.InvoiceMakerUrl + '/EstimateAPI/AddEstimate', body, { headers   }).subscribe(result => {
      debugger;
      this.spinnerService.hide();
      if (result.status == "success") {
        this.companyId = result.data.companyId;
        if (this.isLogoChange) {
          debugger
          const uploadData = new FormData();
          uploadData.append(this.companyId, this.companyLogo, this.companyLogo.name);
          this._http.post<any>(Globals.InvoiceMakerUrl + '/UploadPicAPI/UploadCompanyLogo', uploadData).subscribe(result => {
            debugger;
          }, error => console.error(error));
        } 
        this.toastr.successToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
        this._router.navigate(['/estimate']);
      }
      else {
        this.toastr.errorToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
      }
    }, error => console.error(error));
  }

  onPreview() {
    debugger;
    this.isPreview = true;
    let invoiceItemLst = [];
    let arrInvoiceItem = <FormArray>this.saveForm.get('invoiceItem');
    for (let i = 0; i < arrInvoiceItem.length; i++) {
      let rate = arrInvoiceItem.controls[i].get('rate').value;
      let qty = arrInvoiceItem.controls[i].get('qty').value;
      let subTotal = (parseFloat(rate) * parseFloat(qty)).toFixed(2);
      invoiceItemLst.push({
        itemName: arrInvoiceItem.controls[i].get('itemName').value,
        itemDesc: arrInvoiceItem.controls[i].get('itemDesc').value,
        rate: arrInvoiceItem.controls[i].get('rate').value,
        qty: arrInvoiceItem.controls[i].get('qty').value,
        subTotal: subTotal,

      })
    }
    let taxLabel = this.f.taxLabel.value;
    if (taxLabel == "" || taxLabel == undefined || taxLabel == null) {
      taxLabel = "Tax;"
    }
    let invoicedate = this.f.estimateDate.value;
    let invoiceDateString = "";
    if (invoicedate != "" && invoicedate != undefined &&  invoicedate != null) {
      debugger;
      var dateParts = invoicedate.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
      invoiceDateString = this.datePipe.transform(dateObject, 'dd MMM yyyy');
    }
    let duedate = this.f.dueDate.value;
    let dueDateString = "";
    if (duedate != "" && duedate != undefined && duedate != null) {
      debugger;
      var dateParts = duedate.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      dueDateString = this.datePipe.transform(dateObject, 'dd MMM yyyy');
    }
    this.objDetail = {
      colorCode: this.colorCode,
      estimateTitle: this.f.estimateTitle.value,
      estimateNo: this.f.estimateNumber.value,
      estimateDate: invoiceDateString,
      dueDate: dueDateString,
      ourRemark: this.f.ourRemark.value,
      companyName: this.f.companyName.value,
      companyEmailId: this.f.emailId.value,
      companyLocation: this.location,
      companyAddressLine1: this.f.addressLine1.value,
      companyAddressLine2: this.f.addressLine2.value,
      companyPhoneNo: this.f.phone.value,
      companyBusinessNo: this.f.businessNumber.value,
      clientName: this.f.clientName.value,
      clientEmailId: this.f.clientemailId.value,
      clientLocation: this.clientlocation,
      clientAddress: this.f.clientaddress.value,
      clientPhoneNo: this.f.clientphone.value,
      estimateItemLst: invoiceItemLst,
      currencyCode: this.currencyCode,
      subTotal: this.subTotal,
      finalAmt: this.finalAmount,
      isTaxAttribute: this.isTaxAttribute,
      taxPercent: this.taxPercent,
      taxRateTotal: this.taxRateTotal,
      taxLabel: taxLabel,
      isDiscountAttribute: this.isDiscountAttribute,
      isPercentDisType: this.isPercentDisType,
      discountPercent: this.discountPercent,
      discountTotal: this.DiscountTotal,
      isNegativeFinalAmount: this.isNegativeFinalAmount,
    };
   
  }
  onEdit() {
    this.isPreview = false;
  }
  
}
