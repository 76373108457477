import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './directives/auth.guard';

import { HomeComponent } from './home';
import { LayoutComponent } from './layout';
import { MakeInvoiceComponent, InvoiceListComponent, InvoiceDetailComponent, InvoiceViewComponent} from './invoice';
import { PricingComponent } from './pricing';
import { SignUpComponent, SignInComponent, ChangePasswordComponent } from './user';
import { CompanyComponent, CompanyClientComponent, CompanyClientSaveComponent, ItemsComponent, ItemsSaveComponent } from './company';
import { EstimateDetailComponent, EstimateListComponent, EstimateViewComponent, MakeEstimateComponent} from './estimate';

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'make-invoice', component: MakeInvoiceComponent },
      { path: 'pricing', component: PricingComponent },
      { path: 'signup', component: SignUpComponent },
      { path: 'signin', component: SignInComponent },
      { path: 'setting', component: CompanyComponent },
      { path: 'clients', component: CompanyClientComponent },
      { path: 'client-save', component: CompanyClientSaveComponent },
      { path: 'client-edit/:id', component: CompanyClientSaveComponent },
      { path: 'items', component: ItemsComponent },
      { path: 'item-save', component: ItemsSaveComponent },
      { path: 'item-edit/:id', component: ItemsSaveComponent },
      { path: 'invoices', component: InvoiceListComponent },
      { path: 'invoice/d/:id', component: InvoiceDetailComponent },
      { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      { path: 'estimate', component: EstimateListComponent },
      { path: 'estimate/d/:id', component: EstimateDetailComponent },
      { path: 'make-estimate', component: MakeEstimateComponent },


    ]
  },
  { path: 'v/:id', component: InvoiceViewComponent },
  { path: 'e/:id', component: EstimateViewComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
