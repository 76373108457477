import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalProvider, Globals } from '../directives/global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { IDayCalendarConfig, DatePickerComponent } from "ng2-date-picker";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';  

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html'
})
export class InvoiceViewComponent implements OnInit {
  public objDetail: any;
  headers = new HttpHeaders;
  userId: string = "";
  id: string = "";
  imgCompanyLogo: string = "#";
  pdfInvoiceName: string = "Invoice.pdf";

  constructor(private spinnerService: Ng4LoadingSpinnerService, public _http: HttpClient, private _router: Router, public toastr: ToastrManager,
    public global: GlobalProvider, private _avRoute: ActivatedRoute) {
    if (localStorage.getItem("invSiteUserId") != null && localStorage.getItem("invSiteUserId") != undefined) {
      this.userId = (localStorage.getItem("invSiteUserId"));
    }
    if (this.userId != "" && this.userId != null && this.userId != undefined) {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey).set('User_Id', this.userId);
    }
    else {
      this.headers = new HttpHeaders().set('content-type', Globals.contentType).set('API_Key', Globals.apiKey);
    }
    if (this._avRoute.snapshot.params["id"]) {
      this.id = this._avRoute.snapshot.params["id"];
    }

    this.getDetail();
  }

  ngOnInit() {

  }

  getDetail() {
    this.spinnerService.show();
    const headers = this.headers;
    debugger;
    var body = {
      shortUrl: this.id
    };
    this._http.post<any>(Globals.InvoiceMakerUrl + '/InvoiceAPI/GetInvoiceDetail', body, { headers }).subscribe(result => {
      debugger;
      if (result.status == "success") {
        this.objDetail = result.data.InvocieDetail;
        this.imgCompanyLogo = result.data.InvocieDetail.companyLogo;
        this.pdfInvoiceName = this.id + ".pdf";
      }
      else {
        this.toastr.errorToastr(result.message, null, {
          maxShown: 1,
          toastTimeout: 2000
        });
        this._router.navigate(['/invoices']);

      }
      this.spinnerService.hide();
    }, error => console.error(error));

  }
  onBack() {
    this._router.navigate(['/invoices']);
  }
  public captureScreen() {
    var data = document.getElementById('print-section');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(this.pdfInvoiceName); // Generated PDF   
    });
  }  
}
